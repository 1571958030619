<template>
  <Lock helpless v-on:ready="load()">
    <Overlay :error="error" :reason="reason" class="uk-height-viewport" :action="action" v-on:ok="onOk"></Overlay>
  </Lock>
</template>

<script>
import { mapActions } from 'vuex';

import Lock from '@/components/Lock.vue';
import Overlay from '@/components/Overlay.vue';

export default {
  name: 'OAuth',
  components: {
    Lock,
    Overlay,
  },
  props: {
    wiki: String,
    token: String,
    verifier: String,
    state: String,
  },
  data() {
    return {
      reason: 'completing oauth handshake',
      error: null,
      action: 'oauth',
    };
  },
  methods: {
    load() {
      if (this.token && this.verifier) {
        this.unlock({wiki: this.wiki, verifier: this.verifier, token: this.token}).then(() => {
          if (this.state) {
            this.$router.push(this.state);
          } else {
            this.$router.push('/');
          }
        }).catch((reason) => {
          console.error(reason);
          this.error = this.$message('error.auth');
        });
      } else {
        this.action = 'auth';
        this.error = this.$message('error.auth');
      }
    },
    onOk() {
      if (this.state) {
        this.$router.push(this.state);
      } else {
        this.$router.push('/');
      }
    },
    ...mapActions('api', ['unlock']),
  },
}
</script>