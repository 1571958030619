<template>
  <div class="uk-flex uk-flex-column uk-flex-center uk-background-default">
    <div class="uk-flex uk-flex-center uk-flex-middle uk-flex-auto" v-if="error">
      <div class="uk-text-right">
        <div class="uk-text-lead uk-text-muted">{{ $label(action ? `error.action.${action}` : 'error.unknown') }}</div>
        <div class="uk-text-meta" v-if="download">
          <a :download="download.filename" :href="download.href">
            <i uk-icon="cloud-download" class="uk-margin-vsmall-right"></i>
            {{ error }}
          </a>
        </div>
        <div class="uk-text-meta" v-else>{{ error }}</div>
        <button class="uk-button uk-button-link uk-margin-small-top" v-if="action" v-on:click="$emit('ok')">
          <i uk-icon="link"></i>
          {{ $command(`error.action.${action}`) }}
        </button>
      </div>
      <i class="uk-margin-left uk-text-muted" uk-icon="icon: warning; ratio: 3"></i>
    </div>
    <div class="uk-flex uk-flex-center uk-flex-middle uk-flex-auto" v-else>
      <div class="uk-text-right">
        <div class="uk-text-lead uk-text-muted">{{ $label(status || 'controls.wait') }}</div>
        <div class="uk-text-meta">{{ reason }}</div>
      </div>
      <i class="uk-margin-left uk-text-muted" :uk-icon="`icon: ${icon}; ratio: 3`" v-if="icon"></i>
      <span class="uk-margin-left uk-text-muted" uk-spinner="ratio: 2" v-else></span>
    </div>
    <SiteFooter></SiteFooter>
  </div>
</template>

<script>
import SiteFooter from '@/components/SiteFooter.vue';

export default {
  name: 'Overlay',
  props: {
    status: String,
    icon: String,
    reason: String,
    error: String,
    download: Object,
    action: String,
  },
  components: {
    SiteFooter,
  },
};
</script>